import $axios from 'axios';

const findPermissionUrl = "/permission/findPermission";
const updatePermissionUrl = "/permission/updatePermission";

export const findPermissionApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: findPermissionUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

export const updatePermissionApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: updatePermissionUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}